import cx from 'classnames';

import { themedStoryblockHasTopPadding } from '@hubcms/brand';
import { createGAdExtension, type TeaserGridExtensions } from '@hubcms/domain-teaser-grid';
import { Container } from '@hubcms/ui-container';
import { TeaserGrid, TeaserGridExtensionsProvider } from '@hubcms/ui-teaser-grid';
import { getThemeDataAttributes, isThemed } from '@hubcms/utils-theme';

import styles from './storyblock.module.scss';
import type { StoryblockProps } from './types';

function Storyblock({
  className,
  theme = 'none',
  hasNoPaddingInline = false,
  gridData,
  groupId,
  groupType,
  gridExtensions,
  adElement,
}: StoryblockProps) {
  const extensions: TeaserGridExtensions = {
    ...gridExtensions,
    ...createGAdExtension(adElement),
  };

  const themeDataAttributes = getThemeDataAttributes(theme);

  if (!gridData.items || !gridData.items.length) {
    return null;
  }

  // spreading key prop leads to console errors and we don't actually need a key here
  const { key, ...gridDataWithoutKey } = gridData;

  return (
    <Container
      id={groupId}
      as="section"
      className={cx(styles.storyblock, className, 'no-print', {
        [styles.withTheme]: isThemed(theme),
        [styles.withThemeTopPadding]: themedStoryblockHasTopPadding,
      })}
      fullWidthSm
      fullWidthMd
      fullWidthLg={hasNoPaddingInline}
      {...themeDataAttributes}
      data-testid={`storyblock-type-${groupType}`}
    >
      <TeaserGridExtensionsProvider initialExtensions={extensions}>
        <TeaserGrid {...gridDataWithoutKey} isRoot />
      </TeaserGridExtensionsProvider>
    </Container>
  );
}

export default Storyblock;
